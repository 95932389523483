<template>
  <div class="services">
    <div class="services__hero">
      <heading>
        Услуги
      </heading>

      <service-list />
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import ServiceList from '@/components/Services/ServiceList.vue'

export default {
  components: {
    Heading,
    ServiceList
  }
}
</script>

<style lang="stylus" scoped>
  .services
    &__hero
      margin-bottom 20px
</style>
